import React from 'react';

export class EditTimer extends React.Component<{}, { seconds: number }> {
    private timerId: NodeJS.Timeout | undefined;
    private readonly interval: number;

    constructor(props: any) {
        super(props);
        this.state = {seconds: 1};

        let intervalString: string | null = new URLSearchParams(window.location.search).get('interval');
        let intervalNumber: number = 5;

        if (intervalString != null)
            intervalNumber = parseInt(intervalString);

        if (Number.isNaN(intervalNumber))
            intervalNumber = 5;

        if (intervalNumber < 1 || intervalNumber > 999999)
            intervalNumber = 5

        this.interval = intervalNumber;
    }

    tick() {
        console.log(this.state.seconds);
        this.setState(state => ({
            seconds: state.seconds + 1
        }));
        if (this.state.seconds !== 0 && this.state.seconds % this.interval === 0) {
            let rectangleRed = document.getElementById("RectangleRed")!;
            let rectangleBlue = document.getElementById("RectangleBlue")!;
            let rectangleRedStyle = getComputedStyle(rectangleRed);
            let rectangleBlueStyle = getComputedStyle(rectangleBlue);

            rectangleRed.style.opacity = rectangleRedStyle.opacity === "0" ? "1" : "0";
            rectangleBlue.style.opacity = rectangleBlueStyle.opacity === "0" ? "1" : "0";
        }
    }

    componentDidMount() {
        this.timerId = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
    }

    render() {
        return (
            <>
                <div id="RectangleRed"/>
                <div id="RectangleBlue"/>
            </>
        );
    }
}

import React from 'react';
import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {Homepage} from "./Homepage";
import {EditTimer} from "./EditTimer";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <BrowserRouter>
                    <Routes>
                        <Route index element={<Homepage/>}/>
                        <Route path="/timer" element={<EditTimer/>}/>
                        <Route path="*" element={<Homepage/>}/>
                    </Routes>
                </BrowserRouter>
            </header>
        </div>
    );
}

export default App;

import React from 'react';

export class Homepage extends React.Component<{}, { inputValue: number }> {
    constructor(props: any) {
        super(props);
        this.state = {inputValue: 5};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event: any) {
        this.setState({inputValue: event.target.value});
    }

    handleSubmit(event: any) {
        window.location.href = "/timer?interval=" + this.state.inputValue;
        event.preventDefault();
    }

    render() {
        return (
            <form className="Homepage" onSubmit={this.handleSubmit}>
                <label>
                    Timer:
                    <input className="InputField" type="number" value={this.state.inputValue}
                           onChange={this.handleChange}/>
                    seconds
                </label>
                <br/>
                <input className="SubmitButton" type="submit" value="Submit"/>
            </form>
        );
    }
}
